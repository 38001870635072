import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-eb5',
  templateUrl: './home-eb5.component.html',
  styleUrls: ['./home-eb5.component.css']
})
export class HomeEB5Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onbtn(id: number){
    this.router.navigate(['us-pr/apply', id])
  }

}
