import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-brand-management',
  templateUrl: './brand-management.component.html',
  styleUrls: ['./brand-management.component.css']
})
export class BrandManagementComponent implements OnInit {
d1="Brand ";
d19="Management :";
d2="In a market where products and services are increasingly becoming commodities, a strong and attractive brand is a fundamental asset for business success that involves a series of steps as part of a “branding ladder” as it is the focal point of contact with prospective clients and public face of your business. A brand represents the present, the past and the future of a company and analyzing it means knowing the history of a company and understanding the strengths which is necessary to focus on in order to exploit its potential and competitive advantage, therefore we manage your brand by focusing on those strengths that you as customers value most and by aiming for the achievements that will deliver the greatest contribution";

d3="BRAND SERVICE IT’S ALL ABOUT POSITIONING";
d4="We position your brand by defining a competitive frame of reference by using a variety of brand elements that are inherently chosen to enhance brand awareness as it is the backbone of your business. As part of our global brand management process, we use a standardized market research design to benchmark our performance.";

d5="BRAND BUILDING CREATING A STRONG PRESENCE";
d6="The art of marketing is largely the art of brand building; official company websites act as virtual business cards, portals to engage customers and potential business. At esmsys we no longer believe in making your website presence on the World Wide Web, but it is about hiring a web development company who understands your product, the thought behind it, its best features, the target audience and then creating a website to make the highest impact on it visitors’ by creating a pathway that is unique at every relevant touch point.";

d7="esmsys uses an innovative marketing strategy and various social media, comprising of popular social networks like facebook, twitter, Linkedln, etc. which has successfully taken a conventional marketing and advertising to make the clients business reach worldwide and make your business grow swiftly.";

d8="BRAND DESIGNING MAKING A POSITIVE IMPRINT";
d9="There is an art to ecommerce brand design. The design of your website can make or break a sale, lure-in customers or drive them away. Originality, novelty and professionalism are the essence of every website that we have developed. We provide unique and customized brand designs that are fast loading for the browsers and aesthetically appealing. We offer superior design and programming to ensure your website has a positive impact for your brand.";

d10="Catering to the diverse industrial sectors, our brand design services help your business to achieve high profits and deliver cost-effective web development solution by implementing cutting-edge technologies."

d11="Some of the areas where we excel and work in the field of brand design are:"

d12="– Website Design";

d13="– Brochures and other Collaterals";

d14="– Corporate Identity";

d15="– Print/Indoor/Outdoor Design Advertising";

d16="BRAND MAINTENANCE AND SUPPORT : A 360O APPROACH";
d17="Creating a Web developer and a brand is not a one-time event, but rather an ongoing process for driving business results. The content needs to be alive, current and accurate. With fresh information people will have a reason to return to the site time and again.";

d18="esmsys offers a fully-integrated approach to brand management. Site visitors, customers and members often need a response, they may have questions, comments, complaints and suggestions, and our team handles your customer support, forum moderating and any kind of database management by ensuring that all or parts of these responsibilities fall on our shoulders.";


  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.meta.addTag({
      name: 'description',
      content: 'ESMSYS provides wide variety of IT services like branding, IT infrastructure, Web development, web design, digital marketing and many more. Opt in our services and make your brand ready for tomorrow’s world!'
    })
  }

}
