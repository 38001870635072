import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { Car } from './car';
import { CarService } from './car.service';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-pipefittings',
  templateUrl: './pipefittings.component.html',
  styleUrls: ['./pipefittings.component.css']
})
export class PipefittingsComponent implements OnInit {
  title = 'ESMSYS Offerings in Pipe Fittings';
  cars: Car[];
  error = '';
  alert_box: boolean = false;
  downloadStatus: boolean = false;
  myRecaptcha:boolean;
  car = new Car('', '', '', '', '', '', '', '');

  constructor(private carService: CarService, private http: HttpClient, private titleService: Title, private meta: Meta) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
	
this.meta.addTag({
      name: 'description', 
      content: 'Our patented quick connection products are sold to retailers, business houses and homes across United States. With over 40 U.S. Patents, we push to connect technology than any other company in the world.'
    });
  }

  addCar(f: NgForm) {
    this.alert_box = f.invalid;
    this.resetErrors();

    for (var key in f.controls) {
      f.controls[key].markAsTouched();
    }

    if (!f.invalid) {
      this.loadingStart();
      this.carService.store(this.car)
        .subscribe(
          (res: Car[]) => {

            // Update the list of cars
            this.cars = res;

            this.http.get('../assets/img/Esmsys_Pipe Fitting and Nipple_Catalogue.pdf',
              { responseType: "blob", headers: { 'Accept': 'application/pdf' } })
              .subscribe(blob => {
                saveAs(blob, 'Esmsys_Pipe Fitting and Nipple_Catalogue.pdf');
              })

            setInterval(() => {
              this.loadingStop();
              this.myRecaptcha = false;
            }, 2000);
            f.reset();
            window['grecaptcha'].reset();
          },
          (err) => this.error = err
        );
    }

  }

  loadingStart() {

    this.downloadStatus = true
  }

  loadingStop() {
    this.downloadStatus = false
  }

  private resetErrors() {
    this.error = '';
  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)){
      return false;
    }
    return true;
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }
}
