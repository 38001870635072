import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easy-mda',
  templateUrl: './easy-mda.component.html',
  styleUrls: ['./easy-mda.component.css']
})
export class EasyMDAComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
