import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "https://adani.proplegit.com",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
  }),
};
@Injectable({
  providedIn: 'root'
})
export class EB5GreenCardService {

baseUrl;
token;

constructor(private http: HttpClient, private authService: AuthService) { 
  this.baseUrl = authService.getUrl()
}

  sendmail(data) {
    return this.http.post(`${this.baseUrl}/EB5`, data, httpOptions);
  }
  sendmail2(data) {
    return this.http.post(`${this.baseUrl}/sec_EB5`, data, httpOptions);
  }
  sendmail3(data) {
    return this.http.post(`${this.baseUrl}/thr_EB5`, data, httpOptions);
  }
}
