import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-green-card-family',
  templateUrl: './green-card-family.component.html',
  styleUrls: ['./green-card-family.component.css']
})
export class GreenCardFamilyComponent implements OnInit {
  title = 'USA Green Card and Immigration related Services';

  constructor(private router: Router, private titleService: Title,
    private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({
      name: 'tags', 
      content: 'A Convenient way to Apply, Renew Green Card and Re-entry Permits for United States of America'
    });
    this.meta.addTag({
      name: 'description', 
      content: 'There’s now a more convenient way to apply for a US Green Card through a Job, Marriage, Parent, Child or Sibling, Relative, Business and more... But it’s not for everyone. We can help! Consult us for Green Card related services, US Re-entry Travel Permit, EB5 Investor Visa Interviews, USCIS Medical Exams and more.'
    });
  }

  Onbtnclick(id: number){
    this.router.navigate(['us-pr/apply', id])
  }

}
