import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-green-card-covid19-apply',
  templateUrl: './green-card-covid19-apply.component.html',
  styleUrls: ['./green-card-covid19-apply.component.css']
})
export class GreenCardCovid19ApplyComponent implements OnInit {
  isFormVisible: boolean = true;
  isSuccessVisible: boolean = false;
  isFailVisible: boolean = false;

  id: number = 0;

  
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if(this.activatedRoute.snapshot.url[1].path === 'Thankyou'){
      this.resultPage(true);
    }
    
    this.activatedRoute.params.subscribe((params) => {
      this.id = +params['id'];
    })
  }

  resultPage(isSuccess : boolean){
    this.isFormVisible = false;
    if(isSuccess){
      this.isSuccessVisible = true;
      this.isFailVisible = false;
      window.scrollTo(0,localStorage.topper);
    }else{
      this.isSuccessVisible = false;
      this.isFailVisible = true;
      window.scrollTo(0,localStorage.topper);
    }
  }
  
}
