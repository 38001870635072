import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-covid19-fail',
  templateUrl: './covid19-fail.component.html',
  styleUrls: ['./covid19-fail.component.css']
})
export class Covid19FailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
