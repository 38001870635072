import { Component, OnInit } from '@angular/core';
import {Eb5GujaratiService } from './eb5-gujarati.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-eb5-gujarati',
  templateUrl: './eb5-gujarati.component.html',
  styleUrls: ['./eb5-gujarati.component.css']
})
export class Eb5GujaratiComponent implements OnInit {
  username: any='';
  email: any='';
  phone: any='';
  submitted: boolean;
  error: boolean = false;

  constructor(
    private EB5Service: Eb5GujaratiService,
    private router: Router 

  ) { }

  ngOnInit() {
  }
  sendmail(e) {
    this.submitted = true;
    this.username= this.username.trim();
    this.email= this.email.trim();
    this.phone= this.phone.trim();
    if(e == false || this.username == '' || this.email == '') {
      return;
    } 
    const data= {
        "name" : this.username,
        "phone" : this.phone,
        "email" : this.email,
      }
      this.EB5Service.sendmail(data).subscribe((res: any) => {
        this.error = false;
        this.router.navigate(['/EB5/green-card-appointment'], { queryParams: { name: this.username, phone: this.phone, email: this.email} });
      },(error: any) => {   
        console.log(error.error_code); 
        this.error = true;
        
      });  
  }
}


