import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
// public baseUrl: string = 'http://localhost:8080/';
// public baseUrl: string = 'http://10.100.10.113:9092/';

public baseUrl: string = '/';

  constructor(private http: HttpClient) {
  }

  getUrl(){
    return `${this.baseUrl}api`;
  }
}
