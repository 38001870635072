import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eb5-investment-requirements',
  templateUrl: './eb5-investment-requirements.component.html',
  styleUrls: ['./eb5-investment-requirements.component.css']
})
export class EB5InvestmentRequirementsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
