import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eb5-trackyour-case-status',
  templateUrl: './eb5-trackyour-case-status.component.html',
  styleUrls: ['./eb5-trackyour-case-status.component.css']
})
export class EB5TrackyourCaseStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }
 
}