import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EB5GreenCardService } from '../eb5-green-card/eb5-green-card.service';

@Component({
  selector: 'app-eb5investmentappointmentthankyou',
  templateUrl: './eb5investmentappointmentthankyou.component.html',
  styleUrls: ['./eb5investmentappointmentthankyou.component.css']
})
export class Eb5investmentappointmentthankyouComponent implements OnInit {
  username: any ='';
  phone: any ='';
  email: any ='';
  submitted: boolean;
  error: boolean;
  reason_to_choose_EB5: any ='';
  investInEB5: any ='';
  specific_question: any ='';
  metEB5: any ='';
  submit_request: any ='';
  date: any;
  timing: any;

  constructor(
    private EB5Service: EB5GreenCardService,
    private router: ActivatedRoute,
    private route: Router
  ) {
    this.username = this.router.snapshot.queryParamMap.get('name');
    this.phone = this.router.snapshot.queryParamMap.get('phone');
    this.email = this.router.snapshot.queryParamMap.get('email');
    this.date = this.router.snapshot.queryParamMap.get('date');
    console.log(this.router.snapshot.queryParamMap.get('date'));
    console.log(this.router.snapshot.queryParamMap.get('time'));
    this.timing = this.router.snapshot.queryParamMap.get('time');
  }

  ngOnInit() {
  }
  sendmail() {
    this.submitted = true;
    this.username= this.username.trim();
    this.email= this.email.trim();
    this.phone= this.phone.trim();
    const data= {
      "reason_to_choose_EB5": this.reason_to_choose_EB5,
      "investInEB5": this.investInEB5,
      "specific_question": this.specific_question,
      "metEB5": this.metEB5,
      "submit_request": this.submit_request,
      "name": this.username,
      "email": this.email,
      "phone": this.phone,
    }      
      this.EB5Service.sendmail3(data).subscribe((res: any) => {
        this.error = false;
        console.log(this.date, this.timing);
        this.route.navigate(['/EB5/eb5-submit-you'], { queryParams: { date: this.date, time: this.timing}});
      });  
  }
}
