import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EB5GreenCardService } from '../eb5-green-card/eb5-green-card.service';

@Component({
  selector: 'app-green-card-appointment',
  templateUrl: './green-card-appointment.component.html',
  styleUrls: ['./green-card-appointment.component.css']
})
export class GreenCardAppointmentComponent implements OnInit {
  username: any='';
  email: any='';
  phone: any='';
  address: any='';
  location: any= '';
  day1: any= '';
  day2: any= '';
  day3: any= '';
  day4: any= '';
  day5: any= '';
  day6: any= '';
  day7: any= '';
  time1: any= '';
  time2: any= '';
  time3: any= '';
  date: any= '';
  timing: any= '';
  submitted: boolean;
  error: boolean = false;
  constructor(
    private EB5Service: EB5GreenCardService,
    private router: ActivatedRoute,
    private route: Router

  ) { 
    this.username = this.router.snapshot.queryParamMap.get('name');
    this.phone = this.router.snapshot.queryParamMap.get('phone');
    this.email = this.router.snapshot.queryParamMap.get('email');
  }

  ngOnInit() {
  }
  sendmail() {
    this.submitted = true;
    this.username= this.username.trim();
    this.email= this.email.trim();
    this.phone= this.phone.trim();
    const data= {
        "name" : this.username,
        "phone" : this.phone,
        "email" : this.email,
        "location": this.location,
        "add_comm": this.address,    
        "day": `${this.day1 != '' ? 'Monday,' : ''}${this.day2 != '' ? 'Tuesday,' : ''}${this.day3 != '' ? 'Wednesday,' : ''}${this.day4 != '' ? 'Thursday,' : ''}${this.day5 != '' ? 'Friday,' : ''}${this.day6 != '' ? 'Saturday,' : ''}${this.day7 != '' ? 'Sunday' : ''}`,    
        "times":`${this.time1 != '' ? 'Morning,' : ''}${this.time2 != '' ? 'Afternoon,' : ''}${this.time3 != '' ? 'Evening' : ''}`,    
        "specific_dt": this.date + ' ' + this.timing
      }
      if(data.times[data.times.length-1] == ','){
        data.times = data.times.slice(0, data.times.length-1)
      }
      if(data.day[data.day.length-1] == ','){
        data.day = data.day.slice(0, data.day.length-1)
      }
        this.EB5Service.sendmail2(data).subscribe((res: any) => {
        this.error = false;
        this.route.navigate(['/EB5/eb5investmentappointmentthankyou'], { queryParams: { name: this.username, phone: this.phone, email: this.email, date: this.date, time: this.timing} });
      },(error: any) => {   
        console.log(error.error_code); 
        this.error = true;
        
      });  
  }

}
