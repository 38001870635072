export class Car {
  constructor(
    public fname: string,
    public lname: string,
    public cname: string,
    public dname: string,
    public email: string,
    public phone: string,
    public paddress: string,
    public Intrested: string,
    public id?:   number) {}
}
