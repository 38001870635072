import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';

import { Car } from './car';

@Injectable({
  providedIn: 'root'
})
export class CarService {
  cars: Car[];
  baseUrl;
  token;

  constructor(private http: HttpClient, private authService: AuthService) { 
    this.baseUrl = authService.getUrl()
  }

  getAll(): Observable<Car[]> {
    return this.http.get(`${this.baseUrl}/CurrentOppening`).pipe(
      map((res) => {
        var data = JSON.parse(JSON.stringify(res));
        return data;
      }),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);

    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }
}
