
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from "@angular/router";

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';

import { Quote } from './Quote'

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
baseUrl: string;
quote: Quote[];
token;

constructor(private http: HttpClient, private authService: AuthService, private router: Router) { 
  this.baseUrl = authService.getUrl()
}

  store(quote: Quote): Observable<Quote[]> {
    return this.http.post(`${this.baseUrl}/Quote`, quote)
      .pipe(map((res) => {
        window.location.href = `${this.router.url}/thank-you`;
        
        return this.quote;
        
      }),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);

    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }
}
