import { Component, OnInit } from '@angular/core';
import { Car } from './car';
import { CarService } from './car.service';

@Component({
  selector: 'app-current-openings',
  templateUrl: './current-openings.component.html',
  styleUrls: ['./current-openings.component.css']
})
export class CurrentOpeningsComponent implements OnInit {
  cars: Car[];
  error = '';
  success = '';
  public loading = true;

  constructor(private carService: CarService) {
  }
        
  ngOnInit() {
    this.getCars();
  }
        
  getCars(): void {
    setTimeout(() => {
      this.carService.getAll().subscribe(
        (res: Car[]) => {
          this.loading = false;
          this.cars = res;
        },
        (err) => {
          this.loading = false;
          this.error = err;
        })
    }, 1000);

  }
}
