import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-it-infra',
  templateUrl: './it-infra.component.html',
  styleUrls: ['./it-infra.component.css']
})
export class ItInfraComponent implements OnInit {
  title = 'IT Infrastructure | esmsys';

  constructor(private titleService: Title,
    private meta: Meta) {

    }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({
      name: 'tags', 
      content: 'outsource, it, products, infrastructure, usa, company'
    });
    this.meta.addTag({
      name: 'title', 
      content: 'Outsource IT Infrastructure  |  esmsys'
    });
    this.meta.addTag({
      name: 'description', 
      content: 'Outsource your projects to esmsys and get support of IT infrastructure to run your product smoothly all the time.'
    });
  }

}