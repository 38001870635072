import { Component, OnInit } from '@angular/core';
import { ValuationReport } from './ValuationReport';
import { ValuationReportService } from './ValuationReport.service';

@Component({
  selector: 'app-village-project',
  templateUrl: './village-project.component.html',
  styleUrls: ['./village-project.component.css']
})
export class VillageProjectComponent implements OnInit {
  d1 = "";
  d4 = "Sports Village Project Highlights and Status Update :";
  error = '';
  success = '';
  valuationReports: ValuationReport[];
  valuationReport = new ValuationReport('','','','','','');
  myRecaptcha: boolean;
  constructor(private valuationReportService: ValuationReportService) { }

  ngOnInit() {

  }

  addValuationReport(f) {
    this.resetErrors();
    for (var key in f.controls) {
      f.controls[key].markAsTouched();
    }
    if (!f.invalid) {
      this.valuationReportService.store(this.valuationReport)
      .subscribe(
        (res: ValuationReport[]) => {
          // Update the list of cars
          this.valuationReports = res;

          // Inform the user
          this.success = 'Thank you! Our representative will get in touch with you shortly.';

          // Reset the form
          f.reset();
          window['grecaptcha'].reset();
        },
        (err) => this.error = err
      );
    }
  }

  private resetErrors(){
    this.success = '';
    this.error   = '';
  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)){
      return false;
    }
    return true;
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }
}
