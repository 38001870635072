import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EB5GreenCardService } from '../eb5-green-card/eb5-green-card.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-eb5-submit-you',
  templateUrl: './eb5-submit-you.component.html',
  styleUrls: ['./eb5-submit-you.component.css']
})
export class Eb5SubmitYouComponent implements OnInit {
  date: any = '';
  timing: any = '';

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private EB5Service: EB5GreenCardService,
    private router: ActivatedRoute,
    private route: Router
  ) {
    if(this.router.snapshot.queryParamMap.get('date') !='') {
      this.date = formatDate(this.router.snapshot.queryParamMap.get('date'), 'dd-MM-yyyy' ,this.locale);
    } 
    if(this.router.snapshot.queryParamMap.get('time') !='') {
      this.timing = this.transform(this.router.snapshot.queryParamMap.get('time'));
    }
  }

  ngOnInit() {
  }
  transform(time: any): any {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour > 12 ? 'PM' : 'AM';
    if(parseInt(hour) == 0)
     hour = 12;
    min = (min+'').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour+'').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }

}
