import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proplegit',
  templateUrl: './proplegit.component.html',
  styleUrls: ['./proplegit.component.css']
})
export class ProplegitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
