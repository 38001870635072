import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import {Ng2TelInputModule} from 'ng2-tel-input';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FinanceComponent } from './finance/finance.component';
import { RealEstateComponent } from './real-estate/real-estate.component';
import { EasyimeComponent } from './easyime/easyime.component';
import { JahernoticeComponent } from './jahernotice/jahernotice.component';
import { EasybuilderComponent } from './easybuilder/easybuilder.component';
import { EasyhrComponent } from './easyhr/easyhr.component';
import { PipefittingsComponent } from './pipefittings/pipefittings.component';
import { ThermalinsulationComponent } from './thermalinsulation/thermalinsulation.component';
import { Eb5managementComponent } from './eb5management/eb5management.component';
import { NvcServicesComponent } from './nvc-services/nvc-services.component';
import { BrandManagementComponent } from './brand-management/brand-management.component';
import { BpoCsrComponent } from './bpo-csr/bpo-csr.component';
import { CurrentOpeningsComponent } from './current-openings/current-openings.component';
import { ApplyNowComponent } from './apply-now/apply-now.component';
import { BoardOfDirectorsComponent } from './board-of-directors/board-of-directors.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { TimelineComponent } from './timeline/timeline.component';
import { HttpClientModule } from '@angular/common/http';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { MobileAppComponent } from './mobile-app/mobile-app.component';
import { HeaderrComponent } from './headerr/headerr.component';
import { HeaderrrComponent } from './headerrr/headerrr.component';
import { P1Component } from './p1/p1.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ClientComponent } from './client/client.component';
import { ConstantsService } from './common/services/constants.service';
import { VillageProjectComponent } from './village-project/village-project.component';
import { FittingManufacturComponent } from './fitting-manufactur/fitting-manufactur.component';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { AccordianformComponent } from './accordianform/accordianform.component';
import { RequestOne2OneMeetingComponent } from './request-one2-one-meeting/request-one2-one-meeting.component';
import { ThankyouPageComponent } from './thankyou-page/thankyou-page.component';
import { AuthService } from './auth.service';
import { NgxLoadingModule } from 'ngx-loading';
import { EB5RegionalCenterComponent } from './eb5-regional-center/eb5-regional-center.component';
import { EB5InvestmentRequirementsComponent } from './eb5-investment-requirements/eb5-investment-requirements.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { ItServicesComponent } from './it-services/it-services.component';
import { EB5TrackyourCaseStatusComponent } from './eb5-trackyour-case-status/eb5-trackyour-case-status.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ItInfraComponent } from './it-infra/it-infra.component';
import { GreenCardCOVID19Component } from './green-card-covid19/green-card-covid19.component';
import { GreenCardFamilyComponent } from './green-card-family/green-card-family.component';
import { GreenCardCovid19ApplyComponent } from './green-card-covid19-apply/green-card-covid19-apply.component';
import { Covid19FormComponent } from './green-card-covid19-apply/covid19-form/covid19-form.component';
import { Covid19SuccessComponent } from './green-card-covid19-apply/covid19-success/covid19-success.component';
import { Covid19FailComponent } from './green-card-covid19-apply/covid19-fail/covid19-fail.component';
import { HomeEB5Component } from './home-eb5/home-eb5.component';
import { EasyMDAComponent } from './easy-mda/easy-mda.component';
import { EasyilaComponent } from './easyila/easyila.component';
import { ProplegitComponent } from './proplegit/proplegit.component';
import { Eb5GreenCardComponent } from './eb5-green-card/eb5-green-card.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { LifestyleComponent } from './lifestyle/lifestyle.component';
import { ChrismasComponent } from './chrismas/chrismas.component';
import { GaneshChaturthiComponent } from './ganesh-chaturthi/ganesh-chaturthi.component';
import { NavratriComponent } from './navratri/navratri.component';
import { DiwaliComponent } from './diwali/diwali.component';
import { ThanksGivingDayComponent } from './thanks-giving-day/thanks-giving-day.component';
import { CulturalDayComponent } from './cultural-day/cultural-day.component';
import { PotLuckComponent } from './pot-luck/pot-luck.component';
import { GreenCardAppointmentComponent } from './green-card-appointment/green-card-appointment.component';
import { Eb5investmentappointmentthankyouComponent } from './eb5investmentappointmentthankyou/eb5investmentappointmentthankyou.component';
import { Eb5SubmitYouComponent } from './eb5-submit-you/eb5-submit-you.component';
import { Eb5GujaratiComponent } from './eb5-gujarati/eb5-gujarati.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FinanceComponent,
    RealEstateComponent,
    EasyimeComponent,
    JahernoticeComponent,
    EasybuilderComponent,
    EasyhrComponent,
    PipefittingsComponent,
    ThermalinsulationComponent,
    Eb5managementComponent,
    NvcServicesComponent,
    BrandManagementComponent,
    BpoCsrComponent,
    CurrentOpeningsComponent,
    ApplyNowComponent,
    BoardOfDirectorsComponent,
    OurTeamComponent,
    ContactUsComponent,
    HomeComponent,
    TimelineComponent,
    WebDevelopmentComponent,
    WebDesignComponent,
    MobileAppComponent,
    HeaderrComponent,
    HeaderrrComponent,
    P1Component,
    ClientComponent,
    VillageProjectComponent,
    FittingManufacturComponent,
    AccordianformComponent,
    RequestOne2OneMeetingComponent,
    ThankyouPageComponent,
    EB5RegionalCenterComponent,
    EB5InvestmentRequirementsComponent,
    DisclaimerComponent,
    ItServicesComponent,
    EB5TrackyourCaseStatusComponent,
    ItInfraComponent,
    GreenCardCOVID19Component,
    GreenCardFamilyComponent,
    GreenCardCovid19ApplyComponent,
    Covid19FormComponent,
    Covid19SuccessComponent,
    Covid19FailComponent,
    HomeEB5Component,
    EasyMDAComponent,
    EasyilaComponent,
    ProplegitComponent,
    Eb5GreenCardComponent,
    ThankyouComponent,
    LifestyleComponent,
    ChrismasComponent,
    GaneshChaturthiComponent,
    NavratriComponent,
    DiwaliComponent,
    ThanksGivingDayComponent,
    CulturalDayComponent,
    PotLuckComponent,
    GreenCardAppointmentComponent,
    Eb5investmentappointmentthankyouComponent,
    Eb5SubmitYouComponent,
    Eb5GujaratiComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    EditorModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AccordionModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    // test captcha key
    RecaptchaModule.forRoot({
      siteKey: '6LeGxcgUAAAAAAFvRewaz_ViPBO5n9MOSB1XrYEz',
  }), DateInputsModule,
  Ng2TelInputModule

  ],
  exports:[  CommonModule],
  providers: [ConstantsService, AuthService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
