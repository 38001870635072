import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-real-estate',
  templateUrl: './real-estate.component.html',
  styleUrls: ['./real-estate.component.css']
})
export class RealEstateComponent implements OnInit {
  title = 'ESMSYS Offerings in Real Estate Industry';

  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.meta.addTag({
      name: 'description',
      content: 'We enhance experience for Real Estate industry with real time land/property title clear, ownership profiling, valuation / ease of transactions, Mortgage divisions etc through e-secured platform.'
    });
  }

}
