import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-thankyou-page',
  templateUrl: './thankyou-page.component.html',
  styleUrls: ['./thankyou-page.component.css']
})
export class ThankyouPageComponent implements OnInit {
  Second: string;
  third: string;
  thirdURL: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    var previousPage = this.activatedRoute.snapshot.url[0].path;
    debugger;
    if(previousPage == 'EB5'){
      this.Second = 'USA IMMIGRATION';
      this.third = 'EB5 Management';
      this.thirdURL = '/EB5';
    }
    else if(previousPage == 'NVC'){
      this.Second = 'USA IMMIGRATION';
      this.third = 'NVC SERVICES';
      this.thirdURL = '/NVC';
    }
    else{
      this.Second = 'IT SERVICES';
      if(previousPage == 'Brand'){
        this.third = 'BRAND MANAGEMENT';
        this.thirdURL = '/Brand';
      }
      else if(previousPage == 'BPOCSR'){
        this.third = 'BPO AND CSR';
        this.thirdURL = '/BPOCSR';
      }
      else if(previousPage == 'WebDevelopment'){
        this.third = 'WEB DEVELOPMENT';
        this.thirdURL = '/WebDevelopment';
      }
      else if(previousPage == 'WebDesign'){
        this.third = 'WEB DESIGN';
        this.thirdURL = '/WebDesign';
      }
      else if(previousPage == 'MobileApp'){
        this.third = 'MOBILE APP DEVELOPMENT';
        this.thirdURL = '/MobileApp';
      }
      else if(previousPage == 'it-infrastructure'){
        this.third = 'IT Infrastructure';
        this.thirdURL = '/it-infrastructure';
      }
    }
  }

  ngOnInit() {
  }

}
